import { getEnv, privacyPreferencesDetected } from '../helpers';
/**
 * Heap Wrapper
 * @param {String} method - method name
 * @param {Mixed} data - string, array or object property of the method
 */
export default function heap(method, data) {
    if (!privacyPreferencesDetected) {
        const supportedMethods = ['track', 'identify', 'resetIdentity', 'addUserProperties', 'addEventProperties', 'removeEventProperty', 'clearEventProperties'];
        if (!~supportedMethods.indexOf(method)) {
            console.log('Heap "' + method + '" method is not supported');
            return;
        }
        if (typeof window.heap !== 'undefined') {
            if ('identify' === method || 'track' === method) {
                window.heap[method](data[0], data[1]);
            }
            else {
                window.heap[method](data);
            }
        }
        else {
            console.log('Heap undefined ', method, data);
        }
        if ('production' !== getEnv()) {
            console.log('Heap', method, data);
        }
    }
}
