import { getUrlParamsToObject, isHRCPage, getDefaultSrc, privacyPreferencesDetected } from "helpers";
import { checkLastSrc, getHeapCookieData } from "cookies";
import { track, trackUserAgent, initHeapTracking, initHeapVwo } from '../trackers';
/**
 * Init
 */
function init() {
    let supportedParams = ['formpath', 'projectid'];
    const getParams = getUrlParamsToObject('', true);
    const isHRC = isHRCPage();
    const eventProperties = {};
    const supportedTrackingParams = ['src', 'sub_id', 'sub2_id', 'pub_id', 'account', 'campaign', 'ad_group', 'keyword', 'campaignid', 'adgroupid', 'agid', 'keywordid', 'loc1', 'loc2', 'kw', 'gclid', 'utm_campaign', 'utm_source', 'utm_medium', 'utm_term', 'utm_content', 'program', 'motivation', 'quadlink', 'ccid', 'clk', 'qtr', 'fb_campaign_id', 'yh_campaign_id', 'yh_line_id', 'yh_cr_id', 'yh_ad_id', 'yh_domain', 'yh_subdomain', 'vmcid', 'tk_campaign_id', 'tk_adgroup_id', 'tk_ad_id', 'tk_placement'];
    // Needed for redshift load
    const trackingParamsTranslations = {
        projectid: 'project_id',
        formpath: 'form_path'
    };
    // If not on HRC, then support marketing params as well
    if (!isHRC) {
        supportedParams = supportedParams.concat(supportedTrackingParams);
    }
    // Check if there's a param to identify homeowner
    if (getParams.userid) {
        track('identify', getParams.userid);
    }
    // Check if there's a param to identify contractor
    if (getParams.contractorid) {
        track('identify', getParams.contractorid);
    }
    // Collect supported event properties from URL
    for (let name in getParams) {
        if (getParams.hasOwnProperty(name)) {
            const eventName = typeof trackingParamsTranslations[name] !== "undefined" ? trackingParamsTranslations[name] : name;
            if (~supportedParams.indexOf(name)) {
                eventProperties[eventName] = getParams[name];
            }
        }
    }
    // Set timestamp whenever we find src parameter on url
    if (eventProperties.hasOwnProperty('src') || eventProperties.hasOwnProperty('utm_source')) {
        eventProperties.timestamp = Date.now();
    }
    else if (window.isWordpress) { // I'm sorry
        // Set default src if no tracking params are available (in url or in cookie)
        if (Object.keys(eventProperties).length === 0) {
            const heapCookieData = getHeapCookieData();
            // if no heap cookie, add a src
            if (!heapCookieData) {
                eventProperties.src = getDefaultSrc();
            }
            else if (!heapCookieData.src && !heapCookieData.utm_source) {
                eventProperties.src = getDefaultSrc();
            }
            // 24 hour last touched default check
            else if ((heapCookieData.src || heapCookieData.utm_source) && checkLastSrc()) {
                if (heapCookieData.src) {
                    eventProperties.src = getDefaultSrc();
                }
                else if (heapCookieData.utm_source) {
                    eventProperties.utm_source = '';
                }
            }
        }
    }
    else {
        if (checkLastSrc() && !isHRC) {
            // if not a source in url params, check cookie, and if older than one day, set to mod001
            // but do NOT do this on the HRC
            const heapCookie = getHeapCookieData();
            if (heapCookie.src) {
                eventProperties.src = 'Mod001';
            }
            else if (heapCookie.utm_source) {
                eventProperties.utm_source = '';
            }
        }
    }
    // Check if Heap event props are available from query string
    if (Object.keys(eventProperties).length) {
        if (typeof window.heap === 'undefined') {
            console.log('Heap addEventProperties', eventProperties);
        }
        else {
            // Clear previous Heap event props if there's "src" or "utm_source" param and it's not the HRC
            if (!isHRC && (eventProperties.hasOwnProperty('src') || eventProperties.hasOwnProperty('utm_source'))) {
                window.heap.clearEventProperties();
            }
            window.heap.addEventProperties(eventProperties);
        }
    }
    trackUserAgent();
}
window.addEventListener('load', function () {
    // If heap is already loaded in head tag
    if ('heap' in window) {
        return false;
    }
    if ('IdlizeQueue' in window) {
        window.IdlizeQueue.pushTask(() => {
            // lazy load Heap only if user doesn't have Global Privacy Control enabled (do-not-sell-or-share interactions)
            if (!privacyPreferencesDetected) {
                initHeapTracking();
                if ('_vwo_campaignData' in window && Object.keys(window._vwo_campaignData).length !== 0) {
                    initHeapVwo();
                }
                init();
            }
        });
    }
});
export { init };
